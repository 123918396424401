(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"), require("survey-core"));
	else if(typeof define === 'function' && define.amd)
		define("survey-js-ui", ["jquery", "survey-core"], factory);
	else if(typeof exports === 'object')
		exports["survey-js-ui"] = factory(require("jquery"), require("survey-core"));
	else
		root["SurveyUI"] = factory(root["jQuery"], root["Survey"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_jquery__, __WEBPACK_EXTERNAL_MODULE_survey_core__) {
return 